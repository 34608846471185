exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-info-tsx": () => import("./../../../src/pages/about/info.tsx" /* webpackChunkName: "component---src-pages-about-info-tsx" */),
  "component---src-pages-about-place-tsx": () => import("./../../../src/pages/about/place.tsx" /* webpackChunkName: "component---src-pages-about-place-tsx" */),
  "component---src-pages-about-record-index-tsx": () => import("./../../../src/pages/about/record/index.tsx" /* webpackChunkName: "component---src-pages-about-record-index-tsx" */),
  "component---src-pages-about-record-strapi-record-strapi-id-tsx": () => import("./../../../src/pages/about/record/{strapiRecord.strapi_id}.tsx" /* webpackChunkName: "component---src-pages-about-record-strapi-record-strapi-id-tsx" */),
  "component---src-pages-about-schedule-tsx": () => import("./../../../src/pages/about/schedule.tsx" /* webpackChunkName: "component---src-pages-about-schedule-tsx" */),
  "component---src-pages-admission-tsx": () => import("./../../../src/pages/admission.tsx" /* webpackChunkName: "component---src-pages-admission-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-strapi-blog-strapi-id-tsx": () => import("./../../../src/pages/blog/{strapiBlog.strapi_id}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-blog-strapi-id-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-index-tsx": () => import("./../../../src/pages/education/index.tsx" /* webpackChunkName: "component---src-pages-education-index-tsx" */),
  "component---src-pages-event-2023-11-11-tsx": () => import("./../../../src/pages/event/2023-11-11.tsx" /* webpackChunkName: "component---src-pages-event-2023-11-11-tsx" */),
  "component---src-pages-event-2024-7-20-tsx": () => import("./../../../src/pages/event/2024-7-20.tsx" /* webpackChunkName: "component---src-pages-event-2024-7-20-tsx" */),
  "component---src-pages-event-index-tsx": () => import("./../../../src/pages/event/index.tsx" /* webpackChunkName: "component---src-pages-event-index-tsx" */),
  "component---src-pages-event-strapi-event-strapi-id-tsx": () => import("./../../../src/pages/event/{strapiEvent.strapi_id}.tsx" /* webpackChunkName: "component---src-pages-event-strapi-event-strapi-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-using-tsx": () => import("./../../../src/pages/using.tsx" /* webpackChunkName: "component---src-pages-using-tsx" */),
  "component---src-pages-volunteer-environment-tsx": () => import("./../../../src/pages/volunteer/environment.tsx" /* webpackChunkName: "component---src-pages-volunteer-environment-tsx" */),
  "component---src-pages-volunteer-forestry-tsx": () => import("./../../../src/pages/volunteer/forestry.tsx" /* webpackChunkName: "component---src-pages-volunteer-forestry-tsx" */),
  "component---src-pages-volunteer-index-tsx": () => import("./../../../src/pages/volunteer/index.tsx" /* webpackChunkName: "component---src-pages-volunteer-index-tsx" */),
  "component---src-pages-volunteer-safety-tsx": () => import("./../../../src/pages/volunteer/safety.tsx" /* webpackChunkName: "component---src-pages-volunteer-safety-tsx" */),
  "slice---src-components-announcement-tsx": () => import("./../../../src/components/announcement.tsx" /* webpackChunkName: "slice---src-components-announcement-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

